<template>
    <div>
        <div class="price">
            <span class="large_size">
                $
                <em>{{basePrice.priceleft}}</em>
                <span class="price_decimals">{{basePrice.priceright}}</span>
            </span>
        </div>
    </div>
</template>
<script>
    export default {
        name:'defaultprice',
        props: {
            basePrice:{
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data () {
            return {

            }
        }
    }
</script>
<style scoped lang="scss">
    .price {
        padding: 0 28px;
        margin-top: 22px;
        font-size: 16px;
        line-height: 40px;
        font-weight: 700;
        em {
            font-size: 30px;
        }
        .large_size{
            line-height: 60px;
            color: #f2270c;
            display: inline-block;
            .price_decimals{
                font-size: 18px;
            }
        }
    }
</style>