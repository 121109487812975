<template>
  <div class="detail" style="background:#f2f2f2;">
    <div style="min-height:568px;overflow-y:auto;position: relative;">
      <div style="background:#FFF;">
        <detail-swiper :top-images="data.topImages" style="width: 100%;height: 100%;"></detail-swiper>
        <detail-price :base-price="data.price"></detail-price>
        <base-info :base-info="data.baseinfo"></base-info>
      </div>
      <div>
        <detail-specification :base-data="data.specification"></detail-specification>
        <div class="mar_height"></div>
      </div>
      
    </div>
    <van-goods-action>
      <van-goods-action-icon
        icon="shop-o"
        text="店鋪"
        dot
      />
      <van-goods-action-icon
        icon="cart-o"
        text="購物車"
        badge="5"
      />
      <!-- <van-goods-action-icon
        icon="star"
        text="已收藏"
      /> -->
      <van-goods-action-button
        type="warning"
        text="加入購物車"
        @click="addStore"
      />
      <van-goods-action-button
        type="danger"
        text="立即購買"
      />
    </van-goods-action>
  </div>
</template>

<script>
import { findStore } from '@/api/detail'
import DetailSwiper from './common/swiperPage'
import BaseInfo from './common/baseinfoPage'
import DetailPrice from './common/pricePage'
import DetailSpecification from './common/specificationPage'
export default {
  name: 'detail',
  components: {
    DetailSwiper,
    BaseInfo,
    DetailPrice,
    DetailSpecification
  },
  data() {
    return {
      detailShop: {},
      data:{
        topImages:[require("@/assets/image/dog.jpeg"),require("@/assets/image/dog.jpeg"),require("@/assets/image/dog.jpeg")],
        baseinfo:{
          title:'阳光葡萄/提子 香印青提串装 400g起新鲜水果',
          Activity:'【限时秒杀】生鲜中秋美食特惠，自有品牌好物3件8折！'
        },
        price:{
          priceleft:'13',
          priceright:'.09'
        },
        amount:1,
        specification:{

        }
      }  
    }
  },
  watch: {
    // '$route.query.id': {
    //   handler(newVal) {
    //     this.getfindStore()
    //   },
      // immediate: true
    // }
  },
  created() {
    this.getfindStore()
  },
  methods: {
    getfindStore() {
      findStore(this.$route.query.id).then(data => {
        this.detailShop = data.detail
      }).catch(err => {
        console.log(err)
      })
    },
    addStore() {
      const { id, image, price, title } = this.detailShop
      const currentProduct = {
        id,
        amount: 1,
        image,
        price,
        title
      }
      this.$store.commit('addToCart', currentProduct)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
