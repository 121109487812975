<template>
  <div class="specification">
    <div class="select_sp">
      <div class="sku_choose_info">
        <h3>已选</h3>
        <span>奶白菜400g，2个</span>
        <van-icon class="more" name="ellipsis" @click="spDeatil" />
      </div>
    </div>
    <!-- <van-popup v-model="spshow" closeable round position="bottom" :style="{ height: '70%' }" >
            <div class="main">
                <div class="header">
                    <div>
                        <img src="@/assets/image/dog.jpeg" alt="" class="avt">
                        <p class="price">$
                            <em>13</em>
                            <span class="price_decimals">.09</span>
                        </p>
                        <p class="prop">
                            <span>已选</span>
                            {{count}} 个
                        </p>
                    </div>
                </div>
                <div class="num">
                    <div class="count_choose">
                        <div class="num_wrap_v2">
                            <span class="minus" @click="minusCount"><van-icon class="row" name="minus"/></span>
                            <div class="text_wrap">
                                <input type="text" v-model="count" style="width:34px;text-align:center;border:0px;background: #f2f2f2" value="1">
                            </div>
                            <span class="plus" @click="plusCount"><van-icon class="row"  name="plus"/></span>
                        </div>
                        <p class="count">数量</p>
                    </div>
                </div>
            </div>
            
            <van-goods-action>
                <van-goods-action-button
                    type="warning"
                    text="加入购物车"
                />
                <van-goods-action-button
                    type="danger"
                    text="立即购买"
                />
            </van-goods-action>
        </van-popup> -->
    <van-sku
      v-model="spshow"
      :sku="sku"
      :goods="goods"
      :goods-id="goodsId"
      :quota="quota"
      :quota-used="quotaUsed"
      :hide-stock="sku.hide_stock"
      :initial-sku="initialSku"
      :custom-stepper-config="customStepperConfig"
      @buy-clicked="onBuyClicked"
      @add-cart="onAddCartClicked"
    />
  </div>
</template>
<script>
export default {
  name: "specification",
  props: {
    baseData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      spshow: false,
      count: 1,
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "颜色", // skuKeyName：规格类目名称
            k_s: "s1", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [
              {
                id: "1", // skuValueId：规格值 id
                name: "红色", // skuValueName：规格值名称
                // imgUrl: "https://img01.yzcdn.cn/2.jpg", // 规格类目图片，只有第一个规格类目可以定义图片
                // previewImgUrl: "https://img01.yzcdn.cn/1p.jpg", // 用于预览显示的规格类目图片
              },
              {
                id: "2",
                name: "蓝色",
                // imgUrl: "https://img01.yzcdn.cn/2.jpg",
                // previewImgUrl: "https://img01.yzcdn.cn/2p.jpg",
              },
            ],
            largeImageMode: false, //  是否展示大图模式
          },
          {
            k: "大小", // skuKeyName：规格类目名称
            k_s: "s2", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [
              {
                id: "1", // skuValueId：规格值 id
                name: "大", // skuValueName：规格值名称
                // imgUrl: "https://img01.yzcdn.cn/2.jpg", // 规格类目图片，只有第一个规格类目可以定义图片
                // previewImgUrl: "https://img01.yzcdn.cn/1p.jpg", // 用于预览显示的规格类目图片
              },
              {
                id: "2",
                name: "小",
                // imgUrl: "https://img01.yzcdn.cn/2.jpg",
                // previewImgUrl: "https://img01.yzcdn.cn/2p.jpg",
              },
            ],
            largeImageMode: false, //  是否展示大图模式
          },
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          {
            id: 2259, // skuId
            s1: "1", // 规格类目 k_s 为 s1 的对应规格值 id
            s2: "1", // 规格类目 k_s 为 s2 的对应规格值 id
            price: 100, // 价格（单位分）
            stock_num: 110, // 当前 sku 组合对应的库存,
            goods_id:1111,
          },
          {
            id: 2259, // skuId
            s1: "1", // 规格类目 k_s 为 s1 的对应规格值 id
            s2: "2", // 规格类目 k_s 为 s2 的对应规格值 id
            price: 100, // 价格（单位分）
            stock_num: 110, // 当前 sku 组合对应的库存,
            goods_id:1111,
          },
          {
            id: 2259, // skuId
            s1: "2", // 规格类目 k_s 为 s1 的对应规格值 id
            s2: "1", // 规格类目 k_s 为 s2 的对应规格值 id
            price: 100, // 价格（单位分）
            stock_num: 110, // 当前 sku 组合对应的库存,
            goods_id:1111,
          },
          {
            id: 2259, // skuId
            s1: "2", // 规格类目 k_s 为 s1 的对应规格值 id
            s2: "2", // 规格类目 k_s 为 s2 的对应规格值 id
            price: 100, // 价格（单位分）
            stock_num: 110, // 当前 sku 组合对应的库存,
            goods_id:1111,
          },
        ],
        price: "1.00", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false, // 是否隐藏剩余库存
      },
      quota: 5,//限购数量 
        quotaUsed:2,//已经购买过的数量
        goodsId:1111,
        limitType:'',
      goods: {
        // 默认商品 sku 缩略图
            picture: require("@/assets/image/dog.jpeg")
      },
      customStepperConfig: {
        // 自定义限购文案
        quotaText: '每次限购xxx件',
        // 自定义步进器超过限制时的回调
        handleOverLimit: (data) => {
            const { action, limitType, quota, quotaUsed, startSaleNum } = data;

            if (action === 'minus') {
                Toast(startSaleNum > 1  ? `${startSaleNum}件起售` : '至少选择一件商品');
            } else if (action === 'plus') {
                if (limitType === LIMIT_TYPE.QUOTA_LIMIT) {
                    let msg = `单次限购${quota}件`;
                    if (quotaUsed > 0) msg += `，你已购买${quotaUsed}`;
                    Toast(msg);
                } else {
                    Toast('库存不够了');
                }
            }
        },
            // 步进器变化的回调
            handleStepperChange: currentValue => {},
            // 库存
            stockNum: 1999,
            // 格式化库存
            stockFormatter: stockNum => {},
      },
      initialSku: { //默认选中
        s1: '1',
        s2: '1',
        selectedNum: 3
      },
    };
  },
  methods: {
    spDeatil() {
      this.spshow = true;
    },
    minusCount() {
      if (this.count > 1) {
        this.count--;
      }
    },
    plusCount() {
      this.count++;
    },
    onAddCartClicked(data) { //加入购物车
	    // this.$toast('add cart:' + JSON.stringify(data));
	},
    onBuyClicked(data) { //立即购买
    //   this.$toast('buy:' + JSON.stringify(data));
    //   console.log(JSON.stringify(data))
    }
  },
};
</script>
<style scoped lang="scss">
.specification {
  margin: 22px 0 22px 0;
  padding: 0 28px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  .select_sp {
    background: #fff;
    padding: 28px 0;
    position: relative;
    font-size: 13px;
    color: #8c8c8c;
    .sku_choose_info {
      position: relative;
      padding: 0 40px 0 88px;
      h3 {
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #262626;
        font-weight: 700;
      }
      span {
        font-size: 13px;
        color: #333;
        line-height: 30px;
        display: inline-block;
        margin-top: 5px;
      }
      .more {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
      }
    }
  }
  .main {
    position: absolute;
    width: 100%;
    min-height: 400px;
    max-height: 555px;
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
    .header {
      height: 230px;
      padding: 0 0 0 250px;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      text-align: left;
      margin-bottom: 28px;
      position: relative;
      line-height: 46px;
      font-size: 16px;
      color: #333;
      .avt {
        position: absolute;
        left: 30px;
        top: 30px;
        border-radius: 6px;
        width: 200px;
        height: 200px;
      }
      img {
        border: 0;
        vertical-align: top;
      }
      .price {
        font-family: JDZH-Regular;
        display: inline-block;
        height: 90px;
        line-height: 90px;
        color: #f2270c;
        font-size: 12px;
        font-weight: 700;
      }
      em {
        font-size: 24px;
      }
      .price_decimals {
        font-size: 16px;
      }
      .prop {
        word-break: break-all;
        font-size: 12px;
        color: #333;
        line-height: 1;
        padding-right: 10px;
        span {
          margin-right: 10px;
          color: #8c8c8c;
        }
      }
    }
    .num {
      max-height: 350px;
      padding-bottom: 70px;
    }
    .count_choose {
      padding: 26px 28px;
      font-size: 13px;
    }

    .count {
      color: #262626;
      height: 70px;
      line-height: 70px;
      font-weight: 700;
    }
    .num_wrap_v2 {
      position: relative;
      z-index: 0;
      width: 150px;
      float: right;
      right: 50px;
      vertical-align: middle;
      span {
        position: relative;
        max-width: 30px;
        min-width: 30px;
        height: 28px;
        line-height: 28px;
        background: #fff;
        text-align: center;
      }
      .minus {
        position: relative;
        top: 25px;
        left: 50px;
      }
      .plus {
        position: relative;
        top: -55px;
        left: 180px;
      }
      .text_wrap {
        position: relative;
        width: 45px;
        z-index: 0;
        border-radius: 2px;
        top: -20px;
        left: 90px;
      }
      .row {
        font-weight: 700;
      }
    }
  }
}
</style>