import { render, staticRenderFns } from "./baseinfoPage.vue?vue&type=template&id=42e9c602&scoped=true&"
import script from "./baseinfoPage.vue?vue&type=script&lang=js&"
export * from "./baseinfoPage.vue?vue&type=script&lang=js&"
import style0 from "./baseinfoPage.vue?vue&type=style&index=0&id=42e9c602&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42e9c602",
  null
  
)

export default component.exports