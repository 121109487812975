<template>
    <div class="baseinfo">
        <div class="title">
            {{baseInfo.title}}
        </div>
        <div class="activity">
            {{baseInfo.Activity}}
            {{baseInfo.Activity}}
            {{baseInfo.Activity}}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'baseInfo',
        props: {
            baseInfo:{
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        created() {
            // console.log(this.baseInfo);
        }
    }
</script>
<style scoped>
    .baseinfo{
        padding: 20px 28px;
        overflow: hidden;
        border-radius: 0 0 25px 25px;
    }
    .baseinfo .title {
        font-weight: 700;
        line-height: 41px;
        padding-right: 0;
    }
    .baseinfo .activity {
        padding: 40px 0 0;
        line-height: 1.3;
        overflow: hidden;
        position: relative;
        font-size: 12px;
        color: #666;
    }
</style>
