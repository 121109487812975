<template>
  <van-swipe :autoplay="3000">
  <van-swipe-item v-for="(image, index) in topImages" :key="index">
    <img :src="image" alt style="width:100%;height:100%;"/>
  </van-swipe-item>
</van-swipe>
</template>

<script>
export default {
  name: 'detailswiper',
  props: {
    topImages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  created(){
  }
}
</script>

<style >
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
