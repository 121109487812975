import request from '@/utils/request'
import API from './constants'

/**
 * 根据 id 查询商品详情
 * @param {*} id id
 * @returns
 */
export const findStore = id => request({
  url: API.DETAIL_API,
  method: 'GET',
  params: {
    id:id
  }
})
